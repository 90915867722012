<template>
  <div>
    <portal to="subheader-left">
      <div class="d-flex align-vorgang-center justify-content-between flex-wrap flex-sm-nowrap">
        <h5 class="text-dark font-weight-bold my-5 mr-5">Vorgang Detailansicht</h5>
        <div class="subheader-separator subheader-separator-ver mt-4 mb-2 mr-5 bg-gray-160"></div>
      </div>
    </portal>
    <div class="card card-custom gutter-b" v-if="!isInitialLoading && vorgangObj">
      <div class="card-body">
        <div class="d-flex">
          <div class="flex-grow-1">
            <div class="d-flex align-items-center justify-content-between flex-wrap">
              <div class="mr-3">
                <div class="d-flex">
                  <a
                    href="#"
                    class="d-flex align-items-center text-dark text-nowrap text-hover-primary font-size-h5 font-weight-bold mr-3"
                  >
                    {{ vorgangObj.id }}
                  </a>
                </div>
              </div>
              <div v-if="vorgangObj" class="my-lg-0 my-1"></div>
            </div>
            <div class="d-flex align-items-center flex-wrap justify-content-between">
              <div class="d-flex flex-wrap align-items-center py-2"></div>
            </div>
          </div>
        </div>
        <div class="separator separator-solid my-7"></div>
        <div class="d-flex align-items-center flex-wrap">
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <span class="mr-4">
              <i class="flaticon-piggy-bank icon-2x text-muted font-weight-bold"></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Umsatz</span>
              <span class="font-weight-bolder font-size-h5">
                <span class="text-dark-50 font-weight-bold"></span>
                {{ vorgangObj.brutto | currency }}
              </span>
            </div>
          </div>
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <span class="mr-4">
              <i class="flaticon-file-2 icon-2x text-muted font-weight-bold"></i>
            </span>
            <div class="d-flex flex-column flex-lg-fill">
              <span class="text-dark-75 font-weight-bolder font-size-sm">Bearbeiter</span>
              <span class="font-weight-bolder font-size-h5"> {{ vorgangObj.bearbeiter }}</span>
            </div>
          </div>
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <span class="mr-4">
              <i class="flaticon-globe icon-2x text-muted font-weight-bold"></i>
            </span>
            <div class="d-flex flex-column flex-lg-fill">
              <span class="text-dark-75 font-weight-bolder font-size-sm">Anreiseart</span>
              <span class="font-weight-bolder font-size-h5">{{ vorgangObj.anreiseart }} </span>
            </div>
          </div>
        </div>
        <div class="separator separator-solid my-7"></div>
      </div>
    </div>
    <b-skeleton v-if="isInitialLoading" height="180px" width="600px"></b-skeleton>
  </div>
</template>

<script>
import * as odataService from '@/core/common/services/odata.service';
import EventBus from '@/core/common/plugins/event-bus.js';

export default {
  props: {
    vorgangId: Number,
  },
  data() {
    return {
      isInitialLoading: false,
      isLoading: false,

      vorgangObj: null,
    };
  },
  async mounted() {
    this.isInitialLoading = true;
    this.vorgangObj = await this.getVorgang();
    EventBus.$on('reload', async () => {
      this.vorgangObj = await this.getVorgang();
    });
  },
  methods: {
    async getVorgang() {
      this.isLoading = true;
      const vorgangFilter = { id: parseInt(this.vorgangId) };
      const expand = {
        hotelleistungen: {
          filter: {
            isDeleted: false,
          },
        },
      };
      const filter = [vorgangFilter];
      return odataService
        .getVorgang({ filter, expand })
        .then(response => {
          return response.data[0];
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isInitialLoading = false;
          this.isLoading = false;
        });
    },
  },
};
</script>
